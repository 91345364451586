<template>
<Card class="ma-10" :titulo="'Iniciar proceso'"  :acciones="{activar_tool:false}" >
    <template v-slot:contenido>
        <v-form ref="form" v-model="valid">
             <v-stepper
                v-model="step_formulario"
                vertical
            >
                <v-stepper-step
                :complete="step_formulario > 1"
                step="1"
                >
                Datos del remitente
                <small>Completa todos los campos</small>
                </v-stepper-step>

                <v-stepper-content step="1">
                <Card :titulo="'Datos del remitente'" :acciones="{activar_tool:false}" id="remitente">
                    <template v-slot:contenido>
                        <v-row>
                            <v-col>
                                <v-autocomplete 
                                    filled
                                    rounded
                                    dense
                                    :items="[
                                        {id:'CC',nombre:'Cédula'},
                                        {id:'TI',nombre:'Tarjera de identidad'},
                                        {id:'PA',nombre:'Pasaporte'}
                                    
                                    ]" 
                                    item-text="nombre"
                                    return-object
                                    required
                                    :rules="[(v) => !!v || 'Es requerido un tipo de documento']"
                                    prepend-icon="mdi-file-document" 
                                    v-model="formulario.datos_remitente.tipo_documento"
                                    label="Tipo de documento"></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    type="number"
                                    required
                                    :rules="[(v) => !!v || 'Es requerido un Número de documento']"
                                    v-model="formulario.datos_remitente.numero_documento"
                                    prepend-icon="mdi-file-document" 
                                    filled rounded dense label="Número de documento"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    filled rounded dense 
                                    prepend-icon="mdi-account"
                                    required
                                    :rules="[(v) => !!v || 'Es requerido un nombre']"
                                    v-model="formulario.datos_remitente.nombre"
                                    label="Nombres"
                                >
                                
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    filled rounded dense 
                                    prepend-icon="mdi-domain"
                                    v-model="formulario.datos_remitente.empresa"
                                    label="Empresa"
                                >
                                
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    type="number"
                                    filled rounded dense 
                                    prepend-icon="mdi-phone"
                                  
                                    v-model="formulario.datos_remitente.telefono"
                                    label="Telefono"
                                >
                                
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    filled rounded dense 
                                    prepend-icon="mdi-email"
                                    required
                                    :rules="[v => !!v || 'Es requerido un correo electronico',
                                    v => /.+@.+\..+/.test(v) || 'Email invalido']"
                                    v-model="formulario.datos_remitente.correo"
                                    label="Correo electronico"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">

                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-btn
                                    block
                                    dark
                                    color="indigo darken-4"
                                    @click="step_formulario = 2"
                                >
                                    Continar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </Card>
                
                </v-stepper-content>

                <v-stepper-step
                :complete="step_formulario > 2"
                step="2"
                >
                    Destinatarios
                </v-stepper-step>

                <v-stepper-content step="2">
                <Card :titulo="'Destinatarios'" :acciones="{activar_tool:false}" id="destinatarios">
                    <template v-slot:contenido>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-checkbox
                                        label="Seleccione esta casilla si el ciclo de trabajo será fijo"
                                        color="red darken-4"
                                        v-model="formulario.datos_destinatarios.ciclo_fijo"
                                    >

                                    </v-checkbox>
                                </v-row>
                                <v-row v-if="!formulario.datos_destinatarios.ciclo_fijo">
                                    <WorkFlowDinamico :workflow="[]" />
                                </v-row>
                                <v-row v-else-if="formulario.datos_destinatarios.ciclo_fijo==true">
                                    <v-col>
                                        <v-autocomplete
                                                v-model="formulario.datos_destinatarios.workflow_seleccionado"
                                                :items="workflows"
                                                item-text="nombre"
                                                return-object
                                                prepend-icon="mdi-chart-timeline-variant"
                                                label="Selecciona un flujo de trabajo"
                                                rounded
                                                filled
                                                dense

                                            >
                                            </v-autocomplete>
                                            <v-row
                                                v-if="formulario.datos_destinatarios.workflow_seleccionado!=null
                                                        && formulario.datos_destinatarios.ciclo_fijo==true"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-col cols="11">
                                                    <v-expansion-panels>
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header
                                                        class="title font-weight-light"
                                                        >
                                                        Workflow Seleccionado:
                                                        {{
                                                            formulario.datos_destinatarios.workflow_seleccionado.nombre
                                                        }}</v-expansion-panel-header
                                                        >
                                                        <v-expansion-panel-content>
                                                        <v-sheet
                                                            elevation="2"
                                                            color="grey lighten-2"
                                                            rounded=""
                                                        >
                                                            <v-row>
                                                            <v-col>
                                                                <v-container>
                                                                    <FlowyPlugin :nodes="formulario.datos_destinatarios.workflow_seleccionado.workflow" />
                                                                </v-container>
                                                            </v-col>
                                                            </v-row>
                                                        </v-sheet>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-col>
                                            </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn @click="step_formulario = 1" color="red" block text>
                                                Anterior
                                            </v-btn>
                                    </v-col>
                                
                                    <v-col>
                                        <v-btn
                                                block
                                                dark
                                                color="indigo darken-4"
                                                @click="step_formulario = 3"
                                            >
                                                Continar
                                            </v-btn>
                                    </v-col>
                                    
                                </v-row>
                                
                            </v-col>
                        </v-row>
                    </template>
                </Card>
                
                
                </v-stepper-content>

                <v-stepper-step
                :complete="step_formulario > 3"
                step="3"
                >
                Datos del radicado
                </v-stepper-step>

                <v-stepper-content step="3">
                <Card :titulo="'Datos del radicado'" :acciones="{activar_tool:false}" >
                        <template v-slot:contenido >
                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                        @change="parametros_tipo_documental_seleccionado"
                                        prepend-icon="mdi-file-document"
                                        :items="tipos_documentales"
                                        label="Selecciona el tipo documental"
                                        return-object
                                        item-text="nombre"
                                        v-model="formulario.datos_radicado.tipo_documental_seleccionado"
                                        rounded
                                        filled
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                        <InputsTipoDocumental />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                        rows="2"
                                        required
                                        :rules="[(v) => !!v || 'Es requerido un asunto']"
                                        v-model="formulario.datos_radicado.asunto"
                                        prepend-icon="mdi-file-document" 
                                        filled rounded dense label="Asunto"
                                    ></v-textarea>
                                </v-col>
                                <v-col>
                                    <v-textarea
                                        rows="2"
                                        v-model="formulario.datos_radicado.descripcion"
                                        prepend-icon="mdi-file-document" 
                                        filled rounded dense label="Descripcion"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        type="number"
                                        required
                                        :rules="[(v) => !!v || 'Es requerido un Número de folios']"
                                        v-model="formulario.datos_radicado.folios"
                                        prepend-icon="mdi-file-document" 
                                        filled rounded dense label="Número de folios"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                        <v-text-field
                                        type="number"
                                        required
                                        :rules="[(v) => !!v || 'Es requerido un Número de anexos']"
                                        v-model="formulario.datos_radicado.anexos"
                                        prepend-icon="mdi-file-document" 
                                        filled rounded dense label="Número de anexos"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn block text @click="step_formulario=2" color="red">
                                        Anterior
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        block dark
                                        color="indigo darken-4"
                                        @click="step_formulario = 4"
                                    >
                                        Continuar
                                        
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </Card>
                
                </v-stepper-content>

                <v-stepper-step step="4">
                Adjuntar archivos
                </v-stepper-step>
                <v-stepper-content step="4">
                <Card :titulo="'Adjuntar archivos'" :acciones="{activar_tool:false}" id="archivos"> 
                            <template v-slot:contenido>
                                <v-row>
                                    <v-select label="Tipo de adjuncion de archivos" v-model="metodo_subida_archivos" filled rounded dense :items="['Escaner','Seleccionar archivo','No se subirán archivos al ciclo']" ></v-select>
                                </v-row>
                                <!--- Finalizacion de proceso con seleccion de archivos con input --->
                                <v-row id="Selecion" v-if="metodo_subida_archivos == 'Seleccionar archivo'">
                                    <v-col>
                                        <Drop
                                        :permitir="'nop'"
                                        v-on:info="pruebaDrop"
                                        />
                                    </v-col>
                                    
                                </v-row>

                                <!--- Carousel que muestra las imagenes del escaneo --->
                                <v-row v-else-if="imagenes.length > 0 && metodo_subida_archivos=='Escaner' ">
                                    <Carousel :imagenes="imagenes" @eliminar="eliminar_archivo($event)" />
                                </v-row>

                                <!--- Botones de finalizacion de proceso con escaner --->
                                <v-row id="Escaner">
                                    <v-col>
                                        <v-btn dark block color="indigo darken-4" @click="generar_qr()">
                                            Generar sticker
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                        color="blue darken-3"
                                        
                                        dark

                                        elevation="5"
                                       
                                        block
                                        @click="getImages"
                                        v-if="metodo_subida_archivos == 'Escaner'"
                                        >
                                            ESCANEAR
                                        </v-btn>
                                    </v-col>
                                    <v-col  v-if="imagenes.length > 0">
                                            <v-btn
                                                color="green"
                                                
                                                dark
                                                :disabled="token_caso==''"
                                                elevation="5"
                                                block
                                                @click="iniciar_ciclo_ventanilla_unica()"
                                            
                                            >
                                                Finalizar
                                                <v-icon>mdi-upload</v-icon>
                                            </v-btn>
                                        </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-btn block text color="red" @click="step_formulario = 3">
                                            Anterior
                                        </v-btn>
                                    </v-col> 
                                    <v-col cols="12" sm="6">
                                    
                                    </v-col>   
                                </v-row>
                            </template>
                </Card>
                

                <!--- Dialogo que muestra el codigo QR para revisar el estado del documento  ---->
                <v-dialog v-model="dialogo_qr_informacion_ciclo" max-width="600" persistent >
                    <v-card>
                        <v-card-text>
                        <br>
                        <v-divider></v-divider>
                        <h3 style="text-align:center;color:green">
                            EXITOSO
                        </h3>
                        <v-divider></v-divider>
                        <br>
                        <h3>Los datos para iniciar el ciclo han sido creados exitosamente. El token del ciclo es: {{token_caso}}</h3>
                        
                        <v-img :src="url_qr" class="mx-auto my-5" :aspect-ratio="16/9" contain max-width="450" ></v-img>
                        
                        </v-card-text>
                        <v-card-actions>
                            <v-btn block @click="dialogo_qr_informacion_ciclo=false" color="green" dark> Aceptar </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                </v-stepper-content>
            </v-stepper>
        </v-form>
    </template>
</Card>
</template>
<script>
import FlowyPlugin from "../admin/flowy-vue.vue";
import WorkFlowDinamico from "../comunes/WorkFlowDinamico.vue"
import InputsTipoDocumental from './InputsTipoDocumental.vue'
import Drop from '../Drop.vue'
import Carousel from '../celulas/Carousel.vue'
import  jsPDF  from "jspdf";
const axios = require("axios").default;
export default {
    components: {
        FlowyPlugin,
        WorkFlowDinamico,
        InputsTipoDocumental,
        Drop,
        Carousel,
    },
    data(){
        return{
            
            step_formulario: 1,

            ws: null,

            valid: true,
            metodo_subida_archivos: null,
            files: [],
            imagenes: [],
            datos_archivos: [],
            contadorImagensSubidas: 0,
            indice: 1,

            workflows: [],
            tipos_documentales: [],

            formulario: {
                datos_remitente: {
                                        
                    tipo_documento: null,
                    numero_documento: null,

                    nombre: "",
                    empresa: "",
                    telefono: null,
                    correo: "",
                    direccion_respuesta: "",

                },
                datos_destinatarios: {
                    ciclo_fijo: false,
                   
                    workflow_seleccionado: null
                },
                datos_radicado: {
                    tipo_documental_seleccionado: null,
                    asunto: "",
                    descripcion: "",
                    folios: 0,
                    anexos: 0,
                }
            },

            url_qr: "",
            dialogo_qr_informacion_ciclo:false,
            token_caso: "",
        }
    },
    async created() {
           //this.generar_qr()
            const that = this;
            console.log('Holi')
            window.URL = window.URL || window.webkitURL;
            var wsImpl = window.WebSocket || window.MozWebSocket;

            this.ws = new wsImpl("ws://localhost:8181/");

            this.ws.onmessage = function (e) {
                console.log(e);
                try {
                    if (e.data instanceof Blob) {
                
                        that.seleccionarFoto(e.data);
                        that.addFiles(e.data, that.$store.state.token, that.indice, "blob");
                
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            this.ws.onopen = function () {
                console.log("Opens ");
                console.log(that.indice);
            };
            this.ws.onerror = function (e) {
                console.log(e);
                
                that.$store.commit(
                    "error",
                    "El programa SCAN APP no ha sido activado. Por favor inicielo en el escritorio e ingrese nuevamente a esta vista si desea ESCANEAR archivos, si ese no es el caso ignore este mensaje"
                );
            };

            this.$store.commit("crearToken", 25);
            

            console.log(this.$store.state.token);
            
            this.consultar_workflow();
            this.consultar_tipos_documentales(); 
    },
    methods: {

        eliminar_archivo(indice){
            this.imagenes.splice(indice,1);
            this.datos_archivos.splice(indice,1)
            this.files.splice(indice,1)
        },
        async pruebaDrop(event) {
            console.log("Funcion prueba Drop");
            console.log(event);
            console.log(event.archivo);
            //this.scannerInicio = true;

            for (let index = 0; index < event.archivo.length; index++) {
                await this.addFiles(
                event.archivo[index],
                this.$store.state.token,
                event.obj[index].nombre,
                event.obj[index].tipo
                );
            }
            this.iniciar_ciclo_ventanilla_unica();
            //Comentarios
            // this.archivo2 = event.archivo
            // this.objUpload2 = event.obj
        },
        async addFiles(blob, token, index, tipo) {
            //Se agrego un cuarto parametro llamado tipo el cual contendra
            //El tipo de archivo que se esta agregando .zip png jpg blob etc etc etc.
            //Con el fin de agregarlo al endpoint de almacenamiento y saber como debe hacerse la estructura de las url
            //Que permiten descargar los archivos la cual seria: http://url-endpoint/token/nombre.tipo
            let file = new FormData();
            console.log(token);
            file.append("file", blob);
            file.append("texto", token);
            file.append("nombre", index);

            console.log(blob);

            let obj = {
                nombre: index,
                token: token,
                tipo: tipo,
                autor: this.$store.state.dataUser.dataUser.nombre,
                sector: this.$store.state.dataUser.dataUser.sector,
                nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
                fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
                meta_datos: [],
            };
            
            obj = JSON.stringify(obj);

            this.datos_archivos.push(obj);

            this.files.push(file);
            this.indice++;
        },
        seleccionarFoto(event) {
            console.log(event);
            var reader = new FileReader();

            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData

                var result = e.target.result;

                this.imagenes.push(result);
            };

            reader.readAsDataURL(event);
        },
        async concatenarDatos() {
            let obj = "";
            for (
                let index = 0;
                index <
                this.$store.state.formularios.inputs_tipo_documental.campos.length;
                index++
            ) {
                obj = obj + `"${this.$store.state.formularios.inputs_tipo_documental.campos[index].key}":"${
                                this.$store.state.formularios.inputs_tipo_documental.campos[index].value
                            }",`;
            }
            let objeto = obj.substring(0, obj.length - 1)
            //obj = obj +`"fecha_limite":"${this.$store.state.formularios.inputs_tipo_documental.fecha_limite}"`;
            console.log(objeto);
            obj = "{" + objeto + "}";
            console.log(this.$store.state.formularios.inputs_tipo_documental)

            return obj;
        },

        async consultar_workflow() {
            try {
                let workflows = await this.$store.dispatch('llamado_get',{
                    url: "https://siett.educandote.co/parse/classes/prueba01workflows",
                    tipo_header: "parse"
                })
                this.workflows= [...workflows.data.results]
               
            } catch (error) {
                console.log(error);
            }
        },

        async consultar_tipos_documentales(){
            try {
                let tipos_documentales = await this.$store.dispatch('llamado_get',{
                    url:"https://siett.educandote.co/parse/classes/V1_tiposDocumentales",
                    tipo_header:"parse"
                })
                console.log(tipos_documentales)
                this.tipos_documentales = [...tipos_documentales.data.results]
            } catch (error) {
                console.log(error)
            }
        },

        parametros_tipo_documental_seleccionado(){
                this.$store.state.formularios.inputs_tipo_documental = {
                    campos: [],
                };
                
                console.log(this.formulario.datos_radicado.tipo_documental_seleccionado)
                
                for (let index = 0; index < this.formulario.datos_radicado.tipo_documental_seleccionado.parametros.length; index++) {
                  this.formulario.datos_radicado.tipo_documental_seleccionado.parametros[index].value = ""    
                }
            this.$store.state.formularios.inputs_tipo_documental.campos = [...this.formulario.datos_radicado.tipo_documental_seleccionado.parametros]
               
        },


        async uploadImg() {
            
            this.$store.state.load = true;

            for (let index = 0; index < this.files.length; index++) {
                try {
                this.contadorImagensSubidas++;
                this.$store.commit("auth");

                if (index + 1 == 1) {
                    //Upload docu crea la carpeta y anexa la primera imagen
                    var responseUploadImg = await axios.post(
                    "https://drive1.educandote.co/siett/uploadDocu.php",
                    this.files[index],
                    {
                        header: {
                        "Content-Type": "multipart/form-data",
                        Authorization: this.$store.state.header,
                        },
                    }
                    );
                    console.log("Upload Imagen 1", responseUploadImg);
                } else {
                    //upgradeFolder.php anexa, datos al folder asociado al token

                    var response = await axios.post(
                    "https://drive1.educandote.co/siett/upgradeFolder.php",
                    this.files[index],
                    {
                        header: {
                        "Content-Type": "multipart/form-data",
                        Authorization: this.$store.state.header,
                        },
                    }
                    );

                    console.log("Upload Imagen " + index);
                    console.log(response);
                }
                } catch (error) {
                //Posteriormente indicar cual sera el error en un dialog
                console.log(error);
                this.$store.state.load = false;
                return false; //Se retorna falso cuando existe un error en la subida de alguna imagen
                }
            }

            return true;
        },
        async getImages() {
            this.ws.send("1100");
           
        },

        async iniciar_ciclo_ventanilla_unica() {
            this.$store.state.load = true
            this.contadorImagensSubidas = 0;
            if (this.formulario.datos_remitente.correo != "") {
                if(this.metodo_subida_archivos!='No se subiran archivos al ciclo'){
                    if (this.imagenes.length > 0 || this.datos_archivos.length > 0) {
                
                        var subidaImagenes = await this.uploadImg();
                            if (subidaImagenes) {
                            
                                console.log(this.imagenes.length);
                                await this.enviar_ciclo()
                                //this.subiendoImagenes = false
                            } else {
                                this.$store.commit(
                                    "error",
                                    "Ha ocurrido un error inesperado en el momento de crear la coleccion para almacenar los datos"
                                );
                                console.log("Ha ocurrido un error subiendo alguna imagen");
                                //this.subiendoImagenes = false;
                            }
                        } else {
                            this.$store.commit(
                            "error",
                            "Por favor escanea algunas imagenes antes de continuar en el procedimiento"
                            );
                            //this.subiendoImagenes = false;
                        }
                }else{
                    this.$store.state.load = true;
                    await this.enviar_ciclo()
                    //this.subiendoImagenes = false
                    this.selectDocumento = ""
                }
                
            } else {
                this.$store.commit(
                "error",
                "Por favor coloca un correo electronico al cual se le enviaran los datos de respuesta del caso a la persona que los ha solicitado"
                );
                //this.subiendoImagenes = false;
            }
        },
        async enviar_ciclo(){
            try {
                //Enviar datos al endpoint que contendra todos los casos
                //Y enviar el caso al o los supervisores que esten justo por debajo del punto inicial
                //Para que estos posteriormente lo remitan a sus empleados y empezar con el flujo
                var f = new Date();
                var fecha =f.getFullYear() +"-" + ("0" + (f.getMonth() + 1)).slice(-2) + "-" + ("0" + f.getDate()).slice(-2);
                console.log(fecha); //Esta fecha hay que tomarla con referencia al servidor o al createdAt es mejor //Esto debe hacerse en el perfil de supervisor a la hora de delegar usuarios para responder el caso
                
               
                let workflow = [], sectores
                if(!this.formulario.datos_destinatarios.ciclo_fijo){
                    workflow = await this.$store.dispatch('workflows/agregar_nodos_workflow',
                            {areas: this.$store.state.workflows.areas_seleccionadas, parentId: 1}
                    )
                
                }else{
                    workflow = [...this.formulario.datos_destinatarios.workflow_seleccionado.workflow]
                
                }
                //this.token_caso = await this.$store.dispatch("token_caso");

                //La funcion le pasamos el workflow y el id del nodo al cual queremos encontrarle los sectores
                //a los cuales le enviaremos el ciclo, como este es el inicial le pasamos el 1
                sectores = await this.$store.dispatch('workflows/buscar_sectores',{workflow:workflow, id: 1 });
                let envio_ciclo_todos_los_casos = await this.$store.dispatch('llamado_post',{
                    url: "https://siett.educandote.co/parse/classes/prueba01TodosLosCasos",
                    body: {
                    token: this.token_caso,
                    nombre: this.formulario.datos_radicado.asunto,
                    remitente: this.$store.state.dataUser.dataUser.nombre,
                    tipo_documental: this.formulario.datos_radicado.tipo_documental_seleccionado.nombre,
                    workflow: workflow ,
                    fecha_asignacion_caso: fecha,
                    fecha_int: Date.parse(new Date()),

                    datos_personales_remitente: this.formulario.datos_remitente, //datos del usuario que pide el inicio de una actividad
                    datos_del_radicado: {
                        tipo_documental: this.formulario.datos_radicado.tipo_documental_seleccionado,
                        informacion: {
                            asunto: this.formulario.datos_radicado.asunto,
                            descripcion: this.formulario.datos_radicado.descripcion,
                            folios: this.formulario.datos_radicado.folios,
                            anexos: this.formulario.datos_radicado.anexos,
                        }
                    },
                    //fecha_limite_caso: this.$store.state.formularios.inputs_tipo_documental.fecha_limite,
                    correo_de_contacto: this.formulario.datos_remitente.correo,
                    },
                    tipo_header:"parse"
                })
                
                console.log(envio_ciclo_todos_los_casos);

                let obj = {};
                let datos = await this.concatenarDatos();
                let meta_data = [];
                meta_data.push(datos);
                console.log(meta_data);
                //---Estos campos serviran para saber a quien se le debe notificar una respuesta de aceptado o rechazado
                obj.id_nodo_sector_anterior_numeric = 1; // 01-03 Motivo de reasignacion
                obj.remitente = this.$store.state.dataUser.dataUser.nombre;
                obj.identificacion_remitente = this.$store.state.dataUser.dataUser.identificacion;
                obj.correo_remitente = this.$store.state.dataUser.dataUser.correo;
                obj.area_remitente = this.$store.state.dataUser.dataUser.sector;
                obj.userId_remitente = this.$store.state.dataUser.dataUser.userId;
                obj.inicio = true;
                //-----------------------------------------------------
                //Estos objetos de fechas son para generar los reportes-------
                (obj.fecha_aceptado = 0), //Cambiara de null a la fecha si el caso es aceptado por un supervisor
                (obj.fecha_rechazado = 0), //Cambiara de null a la fecha si el caso es rechazado por un supervisor
                (obj.fecha_respondido = 0), //Cambiara de null a la fecha cuando el caso es respondido por un supervisor
                //-------------------------------------------------------------

                (obj.tipo_documental = this.formulario.datos_radicado.tipo_documental_seleccionado.nombre);
                obj.nombre_documento = this.formulario.datos_radicado.asunto; //Nombre del documento con el cual se recibira en la bandeja de entrada
                obj.meta_data = JSON.parse(meta_data); //Son los inputs del tipo documental seleccionado
                obj.token = this.token_caso; //Token del caso
                obj.estado = "Pendiente"; // Este estado indicara "si esta en progreso, o ya fue respondido, rechazado, aceptado"
                obj.nombre_supervisor = null; //Campo que se llenara una vez algun supervisor haya aceptado el caso
                obj.identificacion_supervisor = null; //se llenera cuando algun supervisor acepte
                obj.correo_supervisor = null; //Se llenara una vez algun supervisor acepte
                obj.responsables = []; // los responsables del area al que ingresa sera un array de objetos de la forma: {nombre: 'fry', identificacion: 1004 } este array se llenara en la vista del supervisor
                
                
                obj.workflow = workflow;  //workflow asignado por el recepcionista que indica las areas por las que pasa el documento
                
                
                obj.fecha_asignacion_caso = fecha; //Punto inicial el dia que el recepcionista coloco el caso
                obj.fecha_asignacion_int = Date.parse(new Date(fecha)), //Para calcular intervalos en los reportes

                obj.fecha_salida = null; // fecha en la que se culmina su proceso dentro del area y es enviado a otra area o al usuario final
                obj.fecha_salida_int=0
                //obj.fecha_limite_caso = this.$store.state.formularios.inputs_tipo_documental.fecha_limite; //Limite legal del documento establecido en el tipo documental

                obj.correo_de_contacto = this.formulario.datos_remitente.correo; //Correo al que se le respondera el caso asignado
                obj.informacion_area = []; //Informacion proporcionado por los empleados o por el mismo en dado caso decida responderlo el mismo supervisor
                obj.carpeta_publica = this.datos_archivos; 
                obj.comentario = this.formulario.datos_radicado.descripcion
                obj.ciclo_fijo = this.formulario.datos_destinatarios.ciclo_fijo

                
                obj.datos_personales_remitente = this.formulario.datos_remitente, //datos del usuario que pide el inicio de una actividad
                obj.datos_del_radicado= {
                    tipo_documental: this.formulario.datos_radicado.tipo_documental_seleccionado,
                    informacion: {
                        asunto: this.formulario.datos_radicado.asunto,
                        descripcion: this.formulario.datos_radicado.descripcion,
                        folios: this.formulario.datos_radicado.folios,
                        anexos: this.formulario.datos_radicado.anexos,
                    }
                }
                
                //Un array de objetos que contendra desde la informacion del recepcionista ademas de las respuestas
                //dadas por cada area, los comentarios etc etc. Cada vez que se haga una respuesta de un area la informacion contenidoa en
                //Informacion area pasara a la siguiente area como informacion adicional a la carpeta publica

                //Esto de aqui debe ir en un ciclo que ejecute tantas veces como bifurcaciones tenga por debajo
                //del punto de inicio el workflow

                console.log(sectores)

                console.log(obj);
                for (let index = 0; index < sectores.length; index++) {
                    obj.area_id = sectores[index].area_id
                    obj.sector = sectores[index].nombre_sector;
                    obj.id_nodo_sector = sectores[index].id_nodo_sector; //Este resulta
                    //De identificar cual es el ID del nodo al que se le envia el caso
                    //Puesto que se puede dar el caso que una misma area remita a multiples oficinas
                    //Y a la hora de dar respuesta se necesita una forma de identificar en que NODO se encuentra
                    //Con un valor unico porque el nombre no nos vale.
                    console.log(obj)
                   
                    let envio_ciclo = await this.$store.dispatch('llamado_post',{
                        url: "https://siett.educandote.co/parse/classes/prueba01casos",
                        body: obj,
                        tipo_header: "parse"
                    })
                    
                    console.log(envio_ciclo);
                }

                this.url_qr = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://audidoc.educandote.co/buscar_estado_documentos/"+this.token_caso
                this.dialogo_qr_informacion_ciclo = true;
                this.$store.state.load = false;
                this.reset();
            } catch (error) {
               
                this.$store.commit(
                    "error",
                    "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion "+error.message
                );
               
            }
                this.$store.state.workflows.workflow = []
                this.$store.state.workflows.areas_seleccionadas = []
        },
        async generar_qr(){
            this.token_caso = await this.$store.dispatch("GENERATE_TOKEN_CASO");
            console.log('xssds')
            var doc = new jsPDF('l', 'mm',[130.23,410]);
            doc.addImage(require('../../assets/escudo_alcaldia.jpg'),"JPG" ,1, 30, 120, 70) 
            doc.setFont("times", "normal");
            doc.setFontSize(30)
            doc.text('SANTA MARTA DTCH', 115, 45);
            
            //doc.setFontSize(10)
            doc.text('Radicado N°:', 115, 70);
            doc.text(this.token_caso, 173, 70);

            doc.text('Remitente:', 115, 85);


            let nombre = this.formulario.datos_remitente.nombre.split(/([a-zA-Z]+\s[a-zA-Z]+)/)
            console.log(nombre) //Dado que la expresion regular valida "cadena" "espacio" "cadena" 
                                //Consideramos el caso en que solo se ingrese un nombre porque el split
                                //Arroja un array con espacios en blanco en las posiciones impares...
            let altura = 95
            doc.text(this.formulario.datos_remitente.nombre,115,altura);

            doc.text('Dep:', 115, 97);
            doc.text(this.$store.state.dataUser.dataUser.nombre_sector,138,97);
            /*
            if(nombre.length==1){
                altura = altura + 8
                doc.text(nombre[0], 115, altura);
            }else{
                for (let index = 0; index < nombre.length; index++) {
                    if(index%2!=0){
                        altura = altura + 8
                        doc.text(nombre[index], 115, altura);
                    }
                }
            }
            */
            
        
            doc.text('Folios: '+this.formulario.datos_radicado.folios,115,altura+13);
            doc.text('Anexos: '+this.formulario.datos_radicado.anexos, 175, altura+13 );
            let url = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://audidoc.educandote.co/buscar_estado_documentos/"+this.token_caso
            doc.addImage(url,"PNG" , 320, 30, 70, 80) 
            doc.save(this.token_caso+'.pdf');
           
        },
        reset(){
            this.token_caso = "";
            this.imagenes = [];
            this.files = [];
            this.indice = 0;
            this.contadorImagensSubidas = 0;
            this.formulario= {
                datos_remitente: {
                                        
                    tipo_documento: null,
                    numero_documento: null,

                    nombre: "",
                    empresa: "",
                    telefono: null,
                    correo: "",
                    direccion_respuesta: "",

                },
                datos_destinatarios: {
                    ciclo_fijo: false,
                    
                    workflow_seleccionado: null
                },
                datos_radicado: {
                 
                    tipo_documental_seleccionado: null,
                    asunto: "",
                    descripcion: "",
                    folios: null,
                    anexos: null,
                }
            },
            this.step_formulario = 1
            this.$store.state.formularios.inputs_tipo_documental = {
                campos: [],
            };
            this.$refs.form.resetValidation()
        }
    }
}
</script>